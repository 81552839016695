var messageToastCompteur = 0;
var infos = {
        /**
         * Fonction d'initialisation du div pour les message. On prepend sur le body
         *
         */
        init: function () {
            if ($('#messages').length === 0) {
                var message = '' +
                    '<!--Messages - Début -->' +
                    '<div id="messages" class="col-xs-6 col-xs-offset-3 text-center">' +
                    '<div id="infos-texte" class="col-xs-11 text-center"></div>' +
                    '<div id="infos-bouton" class="col-xs-1 text-center"><button type="button" class="close-message"><i class="ace-icon fa fa-times"/></button></div>' +
                    '</div>' +
                    '<!--Messages - Fin -->';
                $('body').prepend(message);
            }
        },


        /**
         * Fonction d'affichage de message en entête de page (scrollDown Div)
         * @param {string} message
         * @param {string} icone
         * @param {string} couleur : ce sont les classes css définies dans Ace : black, green, orange, red, etc...
         *
         */
        ajouterMessage: function (message, icone, couleur) {
            infos.init();
            var date = moment().calendar();
            var messageHTML = '<span class="' + couleur + '"><i class="fa ' + icone + '"></i> ' + message + '  -  ' + date + '</span><br>';
            if ($('#infos-texte:contains("Dernière sauvegarde")').length > 0) {
                $('#infos-texte:contains("Dernière sauvegarde")').empty();
            }
            $("#infos-texte").prepend($(messageHTML));
            $("#messages").css("visibility", "visible").hide();
            $("#messages").slideDown();
            $("#infos-bouton").height() === $("#infos-texte").height();
            infos.clique();
        },


        /**
         * Fonction d'affichage de message à la manière de Toast sur Android
         * @param {object} message et paramètres.
         */

        ajouterMessageToast: function (message, nombreFormulaires) {
            var visibleElement = false;
            var element = $("#message-toast");
            var compteurNombreFormulaire = (nombreFormulaires !== 1 && typeof (nombreFormulaires) !== 'undefined') ? nombreFormulaires : 1;
            typeof (message.type) === "undefined" ? message.type = 'ok' : '';
            if (message.type === 'error' && message.icone === "undefined") {
                message.icone = 'fa-exclamation-circle';
                message.messageCouleur = 'red';
            }
            typeof (message.messageDuree) === "undefined" ? message.messageDuree = '1500' : '';
            typeof (message.messageCouleur) === "undefined" ? message.messageCouleur = 'black' : '';
            var options = {
                fadeInTime: 700,
                fadeOutTime: 400,
                easing: "swing",
                callbackAfter: function () {
                    $('body').one('click', function () {
                        if (visibleElement) {
                            element.fadeOut(options.fadeOutTime, options.easing);
                            visibleElement = false;
                        }
                    });
                }
            };
            if (element.length == 0) { // On initialise une seule fois
                element.prepend("message");
            } else {
                $(element).html();
            }
            element.css("background-color", message.messageCouleur);
            // Message déjà initialisé. Soit on cumule les messages, dans le cas de réussites en cascades, soit on affiche le ou les messages d'erreurs.
            if (message.type === 'ok' && (messageToastCompteur === (compteurNombreFormulaire - 1))) {
                $(element).html(infos.ajouterMessageToastBeurre(message));
            } else if ((messageToastCompteur === (compteurNombreFormulaire - 1)) && message.messageCumuler === 'true' && message.type === 'ok') {
                $(element).append('<br>' + infos.ajouterMessageToastBeurre(message));
            } else if (message.type === 'error') {
                $(element).html(infos.ajouterMessageToastBeurre(message));
            }
            if ((message.type === 'ok' && (messageToastCompteur === (compteurNombreFormulaire - 1))) || (message.messageCumuler === 'true') || message.type === 'error') {
                if (message.messageDuree == 0) {
                    setTimeout(function () {
                        element.fadeIn(options.fadeInTime, options.easing, options.callbackAfter);
                        visibleElement = true;
                    }, 0);
                } else {
                    element.fadeIn(options.fadeInTime, options.easing).delay((typeof (message.messageDuree) !== "undefined" ? message.messageDuree : 1500)).fadeOut(options.fadeOutTime, options.easing);
                }
            }
        },

        ajouterMessageToastBeurre: function (message) {
            var messageTailleMax = 50;
            var contenu = (message.icone ? (message.message.length > messageTailleMax ? '<div class="row"><div class="col-xs-1 middle">' : '') + '<span class=' + (message.type ? ' ' + message.type : '') + '><i class="ace-icon fa ' + (message.message.length > messageTailleMax ? ' fa-2x ' : '') + message.icone + (message.iconeCouleur ? ' ' + message.iconeCouleur : '') + '"></i></span>' + (message.message.length > messageTailleMax ? '</div><div class="col-xs-11 middle text-center">' : '') : '') + ' ' + message.message + (message.message.length > messageTailleMax ? '</div></div>' : '');

            return contenu;
        },
    }
; // namespace infos
